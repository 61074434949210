import React from "react";
import "../../App.css";
import Carousel from "./Carousel/Carousel";
import Products from "./Products/Products";

function Home() {
  return (
    <>
      <Carousel />
      <Products />
    </>
  );
}

export default Home;
