import React from "react";
import "./Products.css";
import Card from "../../../components/Helpers/Card/Card";

function Products() {
  return (
    <div className="cards">
      <h1>Check out our Products</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <Card
              src="images/embroidery sunset.jpg"
              text="Artistic Hoops"
              label="Hoops"
              path="/services"
            />
            <Card
              src="images/Tote Bags.jpg"
              text="Tote Bags"
              label="Tote Bags"
              path="/services"
            />
          </ul>
          <ul className="cards__items">
            <Card
              src="images/Slippers.jpg"
              text="Slippers"
              label="Slippers"
              path="/services"
            />
            <Card
              src="images/Cap.jpg"
              text="Caps"
              label="Caps"
              path="/products"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Products;
